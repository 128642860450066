
class FullscreenSearch {
	constructor( trigger ) {
		if (!trigger) return;

		this.trigger = trigger;
		this.isShown = false;

		const href = trigger.href.split("#");
		this.target = document.getElementById(href[href.length-1].toString());
		this.input = this.target.querySelector(".fullscreen-search__input");
		this.closer = this.target.querySelector(".fullscreen-search__close");
		this.closer.setAttribute("aria-controls", this.target.id);

		this.target.setAttribute("aria-modal", "true");

		this.trigger.addEventListener("click", event => {
			event.preventDefault();
			this.open();
		});

		this.closer.addEventListener("click", event => {
			event.preventDefault();
			this.close();
		});
	}

	__keywatcher( event ) {
		if (event.code === "Escape") {
			event.stopPropagation();
			event.preventDefault();
			this.close();
		}

		if (event.code === "Tab") {
			if (event.shiftKey) {
				if (document.activeElement === this.input) {
					this.closer.focus();
					event.preventDefault();
				}
			} else {
				if (document.activeElement === this.closer) {
					this.input.focus();
					event.preventDefault();
				}
			}
		}
	}

	open() {
		this.isShown = true;
		this.target.setAttribute("aria-hidden", "false");
		this.input.focus();
		const keywatcher = this.__keywatcher.bind(this);
		this.target.addEventListener("keydown", keywatcher);
	}

	close() {
		this.isShown = false;
		this.target.setAttribute("aria-hidden", "true");
		this.trigger.focus();
		const keywatcher = this.__keywatcher.bind(this);
		this.target.removeEventListener("keydown", keywatcher);
	}
}

new FullscreenSearch(document.querySelector(".search-button"));
